
import { defineComponent, ref, unref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getPost } from '@/services/api';

import { PartialPost, PostStatus } from '@/interfaces/Post';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialPost & {} = {
  title: '',
  cover: '',
  cover_link: '',
  is_sticky: 0,
  status: PostStatus.PUBLISHED,
  published_at: '',
  created_at: ''
};

export default defineComponent({
  setup() {
    const postId = useRoute().params.id as string;
    const data = ref(DEFAULT_FORM_VALUES);

    onMounted(async() => {
      const res = await getPost({ postId });
      data.value = res.data;
    });

    return {
      data
    };
  }
});
